<template>
  <v-card class="mx-auto py-9 px-6 mt-4" tile elevation="5">
    <template v-if="!isRenew">
      <div class="text-center ">
        <template v-if="!isRenew && !isExpiring && !isFreeTrail">
          <h2 class="mt-1 gradient-text">
            Your Account is under Premium Plan
          </h2>
        </template>
        <template v-else>
          <span class="gradient-text heading"
            >To get all features, upgrade to Premium Plan</span
          >
          <h2 class="mt-1 gradient-text">
            ₹{{ upgradePrice }} one time, then ₹{{ renewPrice }} yearly
          </h2>
        </template>
      </div>
      <div class="mt-4 text-center">
        <div v-if="isFreeTrail">
          Unlock all the following features by upgrading to Premium Plan
        </div>
        <ul class="features-list pl-4">
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Save books data (No limits)
          </li>
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Save members data (No limits)
          </li>
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Generate QR code for each book
          </li>
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Bulk upload library data
          </li>
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Show your books in public search results
          </li>
          <li>
            <v-icon class="green--text">mdi-check</v-icon>
            Create more than one users
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="isExpiring">
      <div class="text-center ">
        <span class="gradient-text heading"
          >To continue using all features, renew your Premium Plan</span
        >
        <h2 class="mt-1 gradient-text">₹{{ renewPrice }}/year</h2>
      </div>
      <div class="mt-4 mb-6">
        Your Premium Plan is going to expire soon, all features will be blocked
        once your plan is expired.
      </div></template
    >
    <template v-else>
      <div class="text-center ">
        <span class="gradient-text heading"
          >To get all features, renew your Premium Plan</span
        >
        <h2 class="mt-2 gradient-text">₹{{ renewPrice }}/year</h2>
      </div>
      <div class="mt-4 mb-6">
        All features all blocked, to unlock all features renew your Premium
        Plan.
      </div>
    </template>
    <div class="text-center" v-if="isRenew || isFreeTrail">
      <v-btn color="primary" tile @click="upgrade">
        Pay ₹{{ isRenew ? renewPrice : upgradePrice }}
        to
        {{ isRenew ? "renew" : "upgrade" }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import store from "@/store";
import helpers from "@/helpers";

export default {
  props: ["isRenew", "isExpiring", "isFreeTrail"],
  data() {
    return {
      upgradePrice: store.app.pricing.upgradePrice,
      renewPrice: store.app.pricing.renewPrice
    };
  },
  methods: {
    upgrade() {
      store.app.showLoader("Please wait...");
      axios
        .post(`${process.env.VUE_APP_API}/getUpgradeOrder`)
        .then(({ data }) => {
          const options = data.options;
          const rzp = new Razorpay({
            ...options,
            handler: (response) => {
              store.app.showLoader("Finishing payment...");
              axios
                .post(`${process.env.VUE_APP_API_NOAUTH}/paymentComplete`, {
                  ...response,
                  order_id: options.order_id
                })
                .then(() => {
                  helpers.getLibraryData();
                  store.app.showDialog(
                    `Successfully ${
                      this.isRenew ? "renewed your " : "upgraded to "
                    } Premium Plan`,
                    `Your payment was completed ${
                      this.isRenew
                        ? "and your Premium Plan is renewed"
                        : "and your account is upgraded to Premium Plan"
                    }  `,
                    "success"
                  );
                  store.billing.state.loadedOnce = false;
                })
                .catch((error) => {
                  return Promise.reject(error);
                })
                .finally(() => {
                  store.app.hideLoader();
                });
            }
          });
          rzp.on("payment.failed", function() {
            store.app.showDialog(
              "Payment incomplete",
              "Your payment was not completed",
              "error"
            );
          });
          rzp.open();
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          store.app.hideLoader();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
}
.features-list {
  text-align: left;
  max-width: 350px;
  margin: 0 auto;
  padding: 12px 0;
  list-style: none;
  color: #7a7a7a;
  li {
    display: flex;
    align-items: flex-start;
    margin-top: 2px;
    i {
      margin-right: 6px;
    }
  }
}
</style>
