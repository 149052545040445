<template>
  <div
    style="height: 100%"
    :class="[
      'settings',
      (expiringSoon || expired || mlState.isFreeTrail) && 'has-warning'
    ]"
  >
    <div class="reports-parent" style="height: 100%" ref="scrollable" v-bar>
      <div>
        <v-container class="lighten-5">
          <v-card class="mx-auto pa-3 px-5 my-4" tile>
            <div class="pt-7" v-if="mlState.sublibrary && !mlState.libraryName">
              <v-skeleton-loader tile type="avatar"></v-skeleton-loader>
              <v-skeleton-loader
                tile
                height="450"
                class="mt-8"
                max-width="350"
                type="sentences@2, text@3, paragraph@3, text@3"
              ></v-skeleton-loader>
              <v-skeleton-loader
                tile
                small
                width="350"
                height="35"
                type="heading"
              ></v-skeleton-loader>
            </div>
            <v-row v-else class="ma-0 settings-wrap">
              <v-col cols="12" sm="12" md="6" class="pa-0 ma-0">
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4 class="mb-1">Logo</h4>
                  <div class="edit-logo">
                    <v-file-input
                      @change="photoAdded"
                      accept="image/*"
                      label="File input"
                      ref="logoFileInput"
                      class="d-none"
                      clearable
                    ></v-file-input>
                    <CropperDialog
                      v-model="showLogoDialog"
                      :src="logoCropperSrc"
                      :onSubmit="updateLogo"
                      submitText="Save"
                    />
                    <v-avatar
                      width="45"
                      height="45"
                      tile
                      :class="[!mlState.libraryHasLogo && 'no-logo']"
                    >
                      <v-img
                        v-if="mlState.libraryHasLogo"
                        :src="mlState.libraryLogoUrl"
                      ></v-img>
                      <v-icon class="edit-logo-p" size="48" v-else
                        >mdi-image</v-icon
                      >
                    </v-avatar>
                    <v-btn
                      class="loading-icon"
                      v-if="logoLoading"
                      :loading="true"
                    ></v-btn>
                    <span v-else>
                      <v-icon
                        @click="logoSelect"
                        class="edit-logo-i settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        @click="confirmDeleteLogo"
                        class="edit-logo-d settings-i"
                        color="error"
                        size="25"
                        v-if="mlState.libraryHasLogo"
                      >
                        mdi-delete
                      </v-icon>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>
                    Username
                  </h4>
                  <div class="edit-text-wrapper">
                    <i class="label grey--text mr-1">
                      {{ mlState.user.username }}
                    </i>
                    <v-btn
                      @click="showPasswordDialog = true"
                      tile
                      x-small
                      color="primary"
                      class="mb-1"
                      >Change Password</v-btn
                    >
                  </div>
                </div>
                <div
                  v-if="!isSubLibrary"
                  class="each-setting-wrapper my-6 pr-4"
                >
                  <h4>Registration Number</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editRegNo">
                      <span class="force-single-line">{{
                        mlState.libraryRegNo
                      }}</span>
                      <v-icon
                        @click="
                          editRegNo = true;
                          newRegNo = mlState.libraryRegNo;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-text-field
                        :disabled="regNoLoading"
                        :loading="regNoLoading"
                        dense
                        v-model="newRegNo"
                        :error="!validate('regNo')"
                        :error-messages="
                          validate('regNo')
                            ? ''
                            : 'Add a valid Registration Number (Max: 25 chars)'
                        "
                      >
                        <v-icon
                          @click="editRegNo = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newRegNo.trim() === mlState.libraryRegNo ||
                              regNoLoading ||
                              !validate('regNo')
                          "
                          @click="updateSettingsByKey('regNo')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-text-field>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>Library Name</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editName">
                      {{ mlState.libraryName }}
                      <v-icon
                        @click="
                          editName = true;
                          newName = mlState.libraryName;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-text-field
                        :disabled="nameLoading"
                        :loading="nameLoading"
                        dense
                        v-model="newName"
                        :error="!validate('name')"
                        :error-messages="
                          validate('name')
                            ? ''
                            : 'Library name must be atleast 5 characters long (Max: 75 chars)'
                        "
                      >
                        <v-icon
                          @click="editName = false"
                          slot="append"
                          color="back"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newName.trim() === mlState.libraryName ||
                              nameLoading ||
                              !validate('name')
                          "
                          @click="updateSettingsByKey('name')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-text-field>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>Email</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editEmail">
                      <span class="force-single-line">{{
                        mlState.libraryEmail
                      }}</span>
                      <v-icon
                        @click="
                          editEmail = true;
                          newEmail = mlState.libraryEmail;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-text-field
                        :disabled="emailLoading"
                        :loading="emailLoading"
                        dense
                        v-model="newEmail"
                        :error="!validate('email')"
                        :error-messages="
                          validate('email') ? '' : 'Valid Email Required'
                        "
                      >
                        <v-icon
                          @click="editEmail = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newEmail.trim() === mlState.libraryEmail ||
                              emailLoading ||
                              !validate('email')
                          "
                          @click="updateSettingsByKey('email')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-text-field>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>Address</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editAddress">
                      <span class="force-single-line">{{
                        mlState.libraryAddress
                      }}</span>
                      <v-icon
                        @click="
                          editAddress = true;
                          newAddress = mlState.libraryAddress;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-textarea
                        :disabled="addressLoading"
                        :loading="addressLoading"
                        dense
                        v-model="newAddress"
                        :error="!validate('address')"
                        :error-messages="
                          validate('address') ? '' : 'Valid Address Required'
                        "
                      >
                        <v-icon
                          @click="editAddress = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newAddress.trim() === mlState.libraryAddress ||
                              addressLoading ||
                              !validate('address')
                          "
                          @click="updateSettingsByKey('address')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-textarea>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>Pin Code</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editPin">
                      <span class="force-single-line">{{
                        mlState.libraryPin
                      }}</span>
                      <v-icon
                        @click="
                          editPin = true;
                          newPin = mlState.libraryPin;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-text-field
                        v-mask="'######'"
                        dense
                        :disabled="pinLoading"
                        :loading="pinLoading"
                        v-model="newPin"
                        :error="!validate('pin')"
                        :error-messages="
                          validate('pin') ? '' : 'Enter a valid Pin Code'
                        "
                      >
                        <v-icon
                          @click="editPin = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            (newPin && newPin.trim() === mlState.libraryPin) ||
                              pinLoading ||
                              !validate('pin')
                          "
                          @click="updateSettingsByKey('pin')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-text-field>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>District</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editDistrict">
                      <span>{{ mlState.libraryDistrict }}</span>
                      <v-icon
                        @click="
                          editDistrict = true;
                          newDistrict = mlState.libraryDistrict;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-select
                        :disabled="districtLoading"
                        :loading="districtLoading"
                        dense
                        v-model="newDistrict"
                        :items="districts"
                      >
                        <v-icon
                          @click="editDistrict = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newDistrict.trim() === mlState.libraryDistrict ||
                              districtLoading
                          "
                          @click="updateSettingsByKey('district')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-select>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>Phone</h4>
                  <div class="edit-text-wrapper">
                    <span class="label" v-if="!editPhone">
                      {{ mlState.libraryPhone }}
                      <v-icon
                        @click="
                          editPhone = true;
                          newPhone = mlState.libraryPhone;
                        "
                        class="edit-logo-i m-1 settings-i"
                        color="primary"
                        size="25"
                        >mdi-pencil
                      </v-icon>
                    </span>
                    <span v-else class="input">
                      <v-text-field
                        v-mask="'############'"
                        dense
                        :disabled="phoneLoading"
                        :loading="phoneLoading"
                        v-model="newPhone"
                        :error="!validate('phone')"
                        :error-messages="
                          validate('phone')
                            ? ''
                            : 'Enter a valid Indian phone number without country code'
                        "
                      >
                        <v-icon
                          @click="editPhone = false"
                          slot="append"
                          color="black"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          :disabled="
                            newPhone.trim() === mlState.libraryPhone ||
                              phoneLoading ||
                              !validate('phone')
                          "
                          @click="updateSettingsByKey('phone')"
                          slot="append"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </v-text-field>
                    </span>
                  </div>
                </div>
                <div class="each-setting-wrapper my-6 pr-4">
                  <h4>
                    Membership Classes<v-btn
                      class="primary ml-2"
                      x-small
                      tile
                      text
                      @click="
                        planForm && planForm.reset(),
                          (editMembershipPlan = { ...newMembershipPlan }),
                          (showMembershipPlan = true)
                      "
                    >
                      Add
                    </v-btn>
                  </h4>
                  <v-skeleton-loader
                    v-if="!membershipPlans"
                    tile
                    class="mt-1 d-flex flex-column justify-space-between"
                    height="90"
                    type="heading,heading,heading"
                  />
                  <div v-else class="edit-text-wrapper">
                    <div
                      v-for="plan in membershipPlans"
                      :key="plan._id"
                      class="label mt-3"
                    >
                      <h5>{{ plan.name }}</h5>
                      <div>
                        Membership Fee: ₹{{ plan.amount }}, Monthly Fee: ₹{{
                          plan.monthlyAmount
                        }}
                        <v-icon
                          @click="
                            (editMembershipPlan = { ...plan }),
                              (showMembershipPlan = true)
                          "
                          class="edit-logo-i m-1 settings-i"
                          color="primary"
                          size="25"
                          >mdi-pencil
                        </v-icon>
                        <v-icon
                          @click="deleteMemberShipPlan(plan._id)"
                          class="edit-logo-d settings-i"
                          color="error"
                          size="25"
                          >mdi-delete
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pa-0 ma-0">
                <div
                  class="each-setting-wrapper my-6 pr-4"
                  v-if="mlState.librarySubscription && !mlState.sublibrary"
                >
                  <div v-if="expired">
                    <h4 class="red--text">
                      Your Premium Plan has been expired!
                    </h4>
                    <UpgradeAccountCard
                      :isRenew="!mlState.isFreeTrail"
                      :isFreeTrail="true"
                    />
                  </div>
                  <div v-else-if="expiringSoon">
                    <h4 class="orange--text">
                      Premium Plan is going to expire soon, will be expired on
                      {{ mlState.librarySubscription.expiryDate | formatDate }}
                    </h4>
                    <UpgradeAccountCard
                      :isRenew="!mlState.isFreeTrail"
                      :isExpiring="true"
                    />
                  </div>
                  <div v-else-if="mlState.isFreeTrail">
                    <h4 class="orange--text">
                      This account is under Free Trial plan which is only valid
                      until
                      {{ mlState.librarySubscription.expiryDate | formatDate }}
                    </h4>
                    <UpgradeAccountCard :isFreeTrail="true" />
                  </div>
                  <h4 v-else class="green--text">
                    This account is under Premium Plan which is valid until
                    {{ mlState.librarySubscription.expiryDate | formatDate }}
                    <UpgradeAccountCard :isRenew="false" :isExpiring="false" />
                  </h4>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </div>
    </div>
    <v-dialog v-model="showPasswordDialog" width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <span> Change Password </span>
        </v-toolbar>

        <v-card-text class="pt-6">
          <v-form
            autocomplete="off"
            ref="confirmForm"
            v-model="passwordFormValid"
            lazy-validation
          >
            <div class="pt-1">Current Password</div>
            <v-text-field
              :rules="[(v) => (v && v.trim().length ? true : 'Required')]"
              type="password"
              class="pt-0"
              v-model="currentPassword"
            ></v-text-field>
            <div class="pt-1">New Password</div>
            <v-text-field
              :rules="[passwordValidation]"
              type="password"
              class="pt-0"
              v-model="newPassword"
            ></v-text-field>
            <div class="pt-1">Confirm New Password</div>
            <v-text-field
              :rules="[confirmPasswordValidation]"
              type="password"
              class="pt-0"
              v-model="newPasswordConfirm"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" tile text @click="cancelPasswordChange()">
            Cancel
          </v-btn>
          <v-btn class="success" tile text @click="updatePassword()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMembershipPlan" width="600">
      <v-card v-if="editMembershipPlan">
        <v-form
          autocomplete="off"
          ref="planForm"
          v-model="planFormValid"
          lazy-validation
          @submit.prevent="saveMemberShipPlan"
        >
          <v-toolbar color="primary" dark>
            <span>
              {{ editMembershipPlan._id ? "Edit" : "New" }} Membership Plan
            </span>
          </v-toolbar>

          <v-card-text class="pt-6">
            <div class="pt-1">Membership Plan Name</div>
            <v-text-field
              :rules="[
                (v) =>
                  v && v.trim().length && v.trim().length <= 20
                    ? true
                    : 'Required (Max: 20 chars)'
              ]"
              class="pt-0"
              v-model="editMembershipPlan.name"
            ></v-text-field>
            <div class="pt-1">Membership Fee</div>
            <v-text-field
              prefix="₹"
              v-mask="'#####'"
              :rules="[(v) => (v !== '' ? true : 'Required')]"
              class="pt-0"
              v-model="editMembershipPlan.amount"
            ></v-text-field>
            <div class="pt-1">Membership Monthly Fee</div>
            <v-text-field
              prefix="₹"
              v-mask="'#####'"
              :rules="[(v) => (v !== '' ? true : 'Required')]"
              class="pt-0"
              v-model="editMembershipPlan.monthlyAmount"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="primary"
              tile
              text
              @click="showMembershipPlan = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="success"
              tile
              text
              :disabled="!planFormValid"
              type="submit"
            >
              {{ editMembershipPlan._id ? "Update" : "Save" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useSettings } from "@/modules";
import CropperDialog from "@/components/CropperDialog";
import UpgradeAccountCard from "@/components/UpgradeAccountCard.vue";
export default {
  components: {
    CropperDialog,
    UpgradeAccountCard
  },
  setup: useSettings
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 960px) {
  .has-warning {
    .settings-wrap {
      flex-direction: column-reverse;
    }
  }
}
</style>
